import React, { useEffect, useState } from 'react';
import './App.css';
import Full_Logo from './Just_Logo.png';
import Title from './Title_PNG.png';
import Huen_Kuen from './Huen_Kuen.png';

function getWidth() {
  const width = Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
  return width;
}

function App() {
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(getWidth())
  }, [])
  return (
    <div className="App">
      <div id="top" style={{
        minHeight: "5vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
        margin: "1rem 0 2rem 0",
      }}>
        <div className="Sub-Text" id="top-left">
          {/* */}
          <img src={Huen_Kuen} alt="Huen Kuen Chinese Writing" style={{ width: "calc(5vw + 5rem)" }} />
        </div>
        <div id="middle">
          <img src={Title} alt="Trowbridge Kung Fu" style={{ width: "calc(20vw + 5rem)" }} />
        </div>
        <div id="top-right">
          <div style={{ background: "rgb(194,194,194)", borderRadius: "50%", width: "calc(3vw + 3rem)", height: "calc(3vw + 3rem)", boxShadow: "rgb(255, 255, 255) 1px 1px 20px 2px" }}>
            <img src={Full_Logo} alt="Trowbridge Kung Fu Logo" style={{ width: "calc(3vw + 3rem)" }} />
          </div>
        </div>
      </div>
      <div id="top" style={{
        height: "30vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        margin: "0 2rem"
      }}>
        <div className="Sub-Text" id="top-left" style={{color: "black"}}>
          <p>Trowbridge Kung Fu Academy</p>
          <p>Affiliated with Bath Kung Fu</p>
          <br/>
        </div>
        <div id="top-mid">
          <h2><i><b>Friendly and Functional for all ages</b></i></h2>
        </div>
        <div className="Sub-Text" id="top-right" style={{color: "black"}}>
          <h3><b>Tel 07717433479 (Jack)</b></h3>
          <p>Places limited! <br/> Book now to guarantee a space!</p>
        </div>
      </div>
      <div id="bottom" className="Bottom-Background"
        style={{
          minHeight: "30vh",
          display: "flex",
          flexDirection: screenWidth > 600 ? "row" : "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          padding: "3rem 0"
        }}>
        <div id="bottom-left" style={{ padding: "2rem 0" }}>
          <h2><i>KIDS</i></h2>
          <p>Tuesdays 18:00 - 18:45</p>
          <p>Staverton C of E Primary</p>
          <p className="Sub-Text">2 Trial Sessions £10</p>
          <p className="Sub-Text">£28 per month</p>
        </div>
        <div style={screenWidth > 600 ?
          { height: "20rem", width: "2px", borderLeft: "#be1e2d 2px solid" }
          : { height: "2px", width: "20rem", borderBottom: "#be1e2d 2px solid" }} />
        <div id="bottom-right" style={{ padding: "2rem 0" }}>
          <h2><i>ADULTS</i></h2>
          <p>Tuesdays 19:00 - 20:30</p>
          <p>Staverton C of E Primary</p>
          <p className="Sub-Text">2 Trial Sessions £14</p>
          <p className="Sub-Text">£37 per month</p>
        </div>
      </div>

    </div>
  );
}

export default App;
